import { MatchType } from "../enums/match-type";
import { SinceDate } from "../enums/since-date";
import {
  PercentDistributionBiasData,
  clonePercentDistributionBiasData,
} from "../stats/ground-stats";
import { UUID } from "../uuid";

export interface ConfidenceLimits {
  battingBlockingConfidenceLimit: number;
  battingDefendingConfidenceLimit: number;
  battingNormalConfidenceLimit: number;
  battingPushingConfidenceLimit: number;
  battingFullPushConfidenceLimit: number;
  battingGlobalConfidenceLimit: number;
  battingBallsFacedPhase1ConfidenceLimit: number;
  battingBallsFacedPhase2ConfidenceLimit: number;
  battingBallsFacedPhase3ConfidenceLimit: number;
  battingBowlTypeConfidenceLimit: number;
  bowlingBlockingConfidenceLimit: number;
  bowlingDefendingConfidenceLimit: number;
  bowlingNormalConfidenceLimit: number;
  bowlingPushingConfidenceLimit: number;
  bowlingFullPushConfidenceLimit: number;
  bowlingGlobalConfidenceLimit: number;
  bowlingBatTypeConfidenceLimit: number;
  groundBlockingConfidenceLimit: number;
  groundDefendingConfidenceLimit: number;
  groundNormalConfidenceLimit: number;
  groundPushingConfidenceLimit: number;
  groundFullPushConfidenceLimit: number;
  groundGlobalConfidenceLimit: number;
  groundBowlTypeConfidenceLimit: number;
}

export interface AdminConfidences {
  strikeRateConfidenceLimits: ConfidenceLimits;
  wicketPercentConfidenceLimits: ConfidenceLimits;
}

export interface MatchSpecificSettings {
  name: string;
  createdAt: number;
  matchTypes: MatchType[];
  matchFormats: UUID[];
  teams: UUID[];
  series: UUID[];
  twmAtLastBallInnings1: number;
  twmAdditionInnings1: number;
  twmAtLastBallInnings2: number;
  twmAdditionInnings2: number;
  secondInningsPushAdjustment: number;
  secondInningsPushDilution: number;
  adminConfidences: AdminConfidences;
  powerplayPushAdjust: number;
  powerplayWicketBias: number;
  powerplayPercentBiases: PercentDistributionBiasData;
  useNewPowerplayModule: boolean;
  surgeStrikeRateBiasInnings1: number;
  surgeWicketBiasInnings1: number;
  surgeStrikeRateBiasInnings2: number;
  surgeWicketBiasInnings2: number;
  spinMultipliersByPhase: number[][];
  paceMultipliersByPhase: number[][];
}

export type AdminPreferencesField = number | string | number[][] | Toggle[];

export enum ToggleType {
  SPLIT_POWERPLAY_AND_NON_POWERPLAY = "SPLIT_POWERPLAY_AND_NON_POWERPLAY",
}

export type Toggle = {
  toggleType: ToggleType;
  enabled: boolean;
};

export const humanReadableToggleTypes: Record<ToggleType, string> = {
  SPLIT_POWERPLAY_AND_NON_POWERPLAY: "Split Powerplay and Non Powerplay",
};

export interface AdminPreferences {
  adminPreferencesId: UUID;
  confidenceForAllTimeStats: number;
  confidenceForRollingStats: number;
  effectivePercentForAllTimeStats: number;
  matchSpecificSettings: MatchSpecificSettings[];
  defaultTwmAtLastBallInnings1: number;
  defaultTwmAdditionInnings1: number;
  defaultTwmAtLastBallInnings2: number;
  defaultTwmAdditionInnings2: number;
  defaultSecondInningsPushAdjustment: number;
  defaultSecondInningsPushDilution: number;
  defaultAdminConfidences: AdminConfidences;
  matchTypeSkewSmoothness: number;
  matchTypeSkewConfidenceLimit: number;
  powerplayPushAdjust: number;
  powerplayWicketBias: number;
  powerplayPercentBiases: PercentDistributionBiasData;
  useNewPowerplayModule: boolean;
  surgeStrikeRateBiasInnings1: number;
  surgeWicketBiasInnings1: number;
  surgeStrikeRateBiasInnings2: number;
  surgeWicketBiasInnings2: number;
  spinMultipliersByPhase: number[][];
  paceMultipliersByPhase: number[][];
  sinceDate: SinceDate;
  toggles: Toggle[];
}

export function cloneMatchSpecificSettings(
  settings: MatchSpecificSettings
): MatchSpecificSettings {
  return {
    name: settings.name,
    createdAt: settings.createdAt,
    matchTypes: [...settings.matchTypes],
    matchFormats: [...settings.matchFormats],
    teams: [...settings.teams],
    series: [...settings.series],
    twmAtLastBallInnings1: settings.twmAtLastBallInnings1,
    twmAdditionInnings1: settings.twmAdditionInnings1,
    twmAtLastBallInnings2: settings.twmAtLastBallInnings2,
    twmAdditionInnings2: settings.twmAdditionInnings2,
    secondInningsPushAdjustment: settings.secondInningsPushAdjustment,
    secondInningsPushDilution: settings.secondInningsPushDilution,
    adminConfidences: settings.adminConfidences,
    powerplayPushAdjust: settings.powerplayPushAdjust,
    powerplayWicketBias: settings.powerplayWicketBias,
    powerplayPercentBiases: clonePercentDistributionBiasData(
      settings.powerplayPercentBiases
    ),
    useNewPowerplayModule: settings.useNewPowerplayModule,
    surgeStrikeRateBiasInnings1: settings.surgeStrikeRateBiasInnings1,
    surgeWicketBiasInnings1: settings.surgeWicketBiasInnings1,
    surgeStrikeRateBiasInnings2: settings.surgeStrikeRateBiasInnings2,
    surgeWicketBiasInnings2: settings.surgeWicketBiasInnings2,
    spinMultipliersByPhase: cloneArray(settings.spinMultipliersByPhase),
    paceMultipliersByPhase: cloneArray(settings.paceMultipliersByPhase),
  };
}

export function isToggleEnabled(adminPreferences: AdminPreferences, toggleType: ToggleType) {
  return adminPreferences?.toggles.find(toggle => toggle.toggleType === toggleType)?.enabled;
}

export function deserializeAdminPreferences(json: any): AdminPreferences {
  return {
    adminPreferencesId: !!json.adminPreferencesId
      ? UUID.fromString(json.adminPreferencesId)
      : null,
    confidenceForAllTimeStats: json.confidenceForAllTimeStats,
    confidenceForRollingStats: json.confidenceForRollingStats,
    effectivePercentForAllTimeStats: json.effectivePercentForAllTimeStats,
    matchSpecificSettings: deserializeMatchSpecificSettings(
      json.matchSpecificSettings
    ),
    defaultTwmAtLastBallInnings1: json.defaultTwmAtLastBallInnings1,
    defaultTwmAdditionInnings1: json.defaultTwmAdditionInnings1,
    defaultTwmAtLastBallInnings2: json.defaultTwmAtLastBallInnings2,
    defaultTwmAdditionInnings2: json.defaultTwmAdditionInnings2,
    defaultSecondInningsPushAdjustment: json.defaultSecondInningsPushAdjustment,
    defaultSecondInningsPushDilution: json.defaultSecondInningsPushDilution,
    defaultAdminConfidences: json.defaultAdminConfidences,
    matchTypeSkewSmoothness: json.matchTypeSkewSmoothness,
    matchTypeSkewConfidenceLimit: json.matchTypeSkewConfidenceLimit,
    powerplayPushAdjust: json.powerplayPushAdjust,
    powerplayWicketBias: json.powerplayWicketBias,
    powerplayPercentBiases: json.powerplayPercentBiases,
    useNewPowerplayModule: json.useNewPowerplayModule,
    surgeStrikeRateBiasInnings1: json.surgeStrikeRateBiasInnings1,
    surgeWicketBiasInnings1: json.surgeWicketBiasInnings1,
    surgeStrikeRateBiasInnings2: json.surgeStrikeRateBiasInnings2,
    surgeWicketBiasInnings2: json.surgeWicketBiasInnings2,
    spinMultipliersByPhase: json.spinMultipliersByPhase,
    paceMultipliersByPhase: json.paceMultipliersByPhase,
    sinceDate: json.sinceDate,
    toggles: json.toggles,
  };
}

export function serializeAdminPreferences(
  adminPreferences: AdminPreferences
): any {
  return {
    adminPreferencesId: !!adminPreferences.adminPreferencesId
      ? adminPreferences.adminPreferencesId.value
      : null,
    confidenceForAllTimeStats: adminPreferences.confidenceForAllTimeStats,
    confidenceForRollingStats: adminPreferences.confidenceForRollingStats,
    effectivePercentForAllTimeStats:
      adminPreferences.effectivePercentForAllTimeStats,
    matchSpecificSettings: serializeMatchSpecificSettings(
      adminPreferences.matchSpecificSettings
    ),
    defaultTwmAtLastBallInnings1: adminPreferences.defaultTwmAtLastBallInnings1,
    defaultTwmAdditionInnings1: adminPreferences.defaultTwmAdditionInnings1,
    defaultTwmAtLastBallInnings2: adminPreferences.defaultTwmAtLastBallInnings2,
    defaultTwmAdditionInnings2: adminPreferences.defaultTwmAdditionInnings2,
    defaultSecondInningsPushAdjustment:
      adminPreferences.defaultSecondInningsPushAdjustment,
    defaultSecondInningsPushDilution:
      adminPreferences.defaultSecondInningsPushDilution,
    defaultAdminConfidences: adminPreferences.defaultAdminConfidences,
    matchTypeSkewSmoothness: adminPreferences.matchTypeSkewSmoothness,
    matchTypeSkewConfidenceLimit: adminPreferences.matchTypeSkewConfidenceLimit,
    powerplayPushAdjust: adminPreferences.powerplayPushAdjust,
    powerplayWicketBias: adminPreferences.powerplayWicketBias,
    powerplayPercentBiases: adminPreferences.powerplayPercentBiases,
    useNewPowerplayModule: adminPreferences.useNewPowerplayModule,
    surgeStrikeRateBiasInnings1: adminPreferences.surgeStrikeRateBiasInnings1,
    surgeWicketBiasInnings1: adminPreferences.surgeWicketBiasInnings1,
    surgeStrikeRateBiasInnings2: adminPreferences.surgeStrikeRateBiasInnings2,
    surgeWicketBiasInnings2: adminPreferences.surgeWicketBiasInnings2,
    spinMultipliersByPhase: adminPreferences.spinMultipliersByPhase,
    paceMultipliersByPhase: adminPreferences.paceMultipliersByPhase,
    sinceDate: adminPreferences.sinceDate,
    toggles: adminPreferences.toggles,
  };
}

function cloneArray(arr: number[][]): number[][] {
  const len = arr.length;
  const copy = new Array(len);
  for (let i = 0; i < len; ++i) {
    copy[i] = arr[i].slice();
  }
  return copy;
}

function deserializeMatchSpecificSettings(
  matchSpecificSettings: any[]
): MatchSpecificSettings[] {
  const list: MatchSpecificSettings[] = [];

  matchSpecificSettings.forEach((entry: any) => {
    const matchTypes: MatchType[] = [];
    const matchFormats: UUID[] = [];
    const teams: UUID[] = [];
    const series: UUID[] = [];

    (entry.matchTypes as any[]).forEach((value) => {
      matchTypes.push(MatchType[value]);
    });

    (entry.matchFormats as any[]).forEach((value) => {
      matchFormats.push(UUID.fromString(value));
    });

    (entry.teams as any[]).forEach((value) => {
      teams.push(UUID.fromString(value));
    });

    (entry.series as any[]).forEach((value) => {
      series.push(UUID.fromString(value));
    });

    const settings: MatchSpecificSettings = {
      name: entry.name,
      createdAt: entry.createdAt,
      matchTypes,
      matchFormats,
      teams,
      series,
      twmAtLastBallInnings1: entry.twmAtLastBallInnings1,
      twmAdditionInnings1: entry.twmAdditionInnings1,
      twmAtLastBallInnings2: entry.twmAtLastBallInnings2,
      twmAdditionInnings2: entry.twmAdditionInnings2,
      secondInningsPushAdjustment: entry.secondInningsPushAdjustment,
      secondInningsPushDilution: entry.secondInningsPushDilution,
      adminConfidences: entry.adminConfidences,
      powerplayPushAdjust: entry.powerplayPushAdjust,
      powerplayWicketBias: entry.powerplayWicketBias,
      powerplayPercentBiases: entry.powerplayPercentBiases,
      useNewPowerplayModule: entry.useNewPowerplayModule,
      surgeStrikeRateBiasInnings1: entry.surgeStrikeRateBiasInnings1,
      surgeWicketBiasInnings1: entry.surgeWicketBiasInnings1,
      surgeStrikeRateBiasInnings2: entry.surgeStrikeRateBiasInnings2,
      surgeWicketBiasInnings2: entry.surgeWicketBiasInnings2,
      spinMultipliersByPhase: entry.spinMultipliersByPhase,
      paceMultipliersByPhase: entry.paceMultipliersByPhase,
    };

    list.push(settings);
  });

  return list;
}

function serializeMatchSpecificSettings(
  matchSpecificSettings: MatchSpecificSettings[]
): any[] {
  const list: any[] = [];

  matchSpecificSettings.forEach((entry: MatchSpecificSettings) => {
    const matchTypes: string[] = [];
    const matchFormats: string[] = [];
    const teams: string[] = [];
    const series: string[] = [];

    entry.matchTypes.forEach((value) => {
      matchTypes.push(value);
    });

    entry.matchFormats.forEach((id) => {
      matchFormats.push(id.value);
    });

    entry.teams.forEach((id) => {
      teams.push(id.value);
    });

    entry.series.forEach((id) => {
      series.push(id.value);
    });

    const settings: any = {
      name: entry.name,
      createdAt: entry.createdAt,
      matchTypes,
      matchFormats,
      teams,
      series,
      twmAtLastBallInnings1: entry.twmAtLastBallInnings1,
      twmAdditionInnings1: entry.twmAdditionInnings1,
      twmAtLastBallInnings2: entry.twmAtLastBallInnings2,
      twmAdditionInnings2: entry.twmAdditionInnings2,
      secondInningsPushAdjustment: entry.secondInningsPushAdjustment,
      secondInningsPushDilution: entry.secondInningsPushDilution,
      adminConfidences: entry.adminConfidences,
      powerplayPushAdjust: entry.powerplayPushAdjust,
      powerplayWicketBias: entry.powerplayWicketBias,
      powerplayPercentBiases: entry.powerplayPercentBiases,
      useNewPowerplayModule: entry.useNewPowerplayModule,
      surgeStrikeRateBiasInnings1: entry.surgeStrikeRateBiasInnings1,
      surgeWicketBiasInnings1: entry.surgeWicketBiasInnings1,
      surgeStrikeRateBiasInnings2: entry.surgeStrikeRateBiasInnings2,
      surgeWicketBiasInnings2: entry.surgeWicketBiasInnings2,
      spinMultipliersByPhase: entry.spinMultipliersByPhase,
      paceMultipliersByPhase: entry.paceMultipliersByPhase,
    };

    list.push(settings);
  });

  return list;
}
