import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import Filter7Icon from "@mui/icons-material/Filter7";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import {
  Box,
  Collapse,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { Fragment, useState } from "react";

import { MatchInfo } from "../../types/entities/match-info";
import { services } from "../../types/services";
import {
  InningsRunsBetData,
  MatchOddsBetData,
} from "../../types/stored-bets/bet-data";
import { BetSource } from "../../types/stored-bets/bet-source";
import {
  BetType,
  betTypeReadableValues,
} from "../../types/stored-bets/bet-type";
import { StoredBet } from "../../types/stored-bets/stored-bet";
import { StoredGameStateOdds } from "../../types/stored-bets/stored-odds";
import { UUID } from "../../types/uuid";
import { CreationDialog } from "../my-matches/match-creation-modals/creation-dialog";
import TooltipIconButton from "../navigation-bar/tooltip-icon-button";
import { format } from "../simulator-page/simulator-utils";

interface BetOdds {
  odds: number;
  innings: number;
  overs: string;
  score: string;
  nextGameStateInnings: number;
  nextGameStateOvers: string;
  nextGameStateScore: string;
}

interface Props {
  groupedBets: { [matchId: string]: StoredBet[] };
  groupedOdds: { [matchId: string]: StoredGameStateOdds[] };
  matches: MatchInfo[];
  linksBySourceEventId: { [sourceEventId: string]: UUID };
  onBetsUpdated: () => void;
  setLoading: (loading: boolean) => void;
}

export default function BetHistoryDisplay({
  groupedBets,
  groupedOdds,
  matches,
  linksBySourceEventId,
  onBetsUpdated,
  setLoading,
}: Readonly<Props>) {
  const [openMatches, setOpenMatches] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [isLinkModalOpen, setIsLinkModalOpen] = useState<boolean>(false);
  const [selectedBetId, setSelectedBetId] = useState<string>(null);

  const FIELDS_TO_HIDE = [
    "teamid",
    "subtype",
    "playerid",
    "fulljson",
    "originalbettype",
  ];

  const toggleMatch = (key: string) => {
    setOpenMatches((prevOpenMatches) => ({
      ...prevOpenMatches,
      [key]: !prevOpenMatches[key],
    }));
  };

  const getOurOdds = (bet: StoredBet): BetOdds => {
    if (!bet.matchId || !bet.gameStateId) {
      return null;
    }

    const matchOddsList = groupedOdds[bet.matchId.value];
    const gameStateOdds = matchOddsList?.find(
      (odds) =>
        odds.matchId === bet.matchId.value &&
        odds.gameStateId === bet.gameStateId.value
    );

    if (gameStateOdds === undefined) {
      return null;
    }

    let userOdds = null;

    switch (bet.betType) {
      case BetType.MATCH_ODDS:
      case BetType.SUPER_OVER:
        userOdds = getMatchOdds(bet, gameStateOdds);
        break;
      case BetType.INNINGS_RUNS:
        userOdds = getRunsLineOdds(bet, gameStateOdds);
        break;
      default:
        return null;
    }

    return {
      odds: userOdds,
      innings: gameStateOdds.innings,
      overs: gameStateOdds.overs,
      score: gameStateOdds.score,
      nextGameStateInnings: gameStateOdds.nextGameStateInnings,
      nextGameStateOvers: gameStateOdds.nextGameStateOvers,
      nextGameStateScore: gameStateOdds.nextGameStateScore,
    };
  };

  const getMatchOdds = (
    bet: StoredBet,
    gameStateOdds: StoredGameStateOdds
  ): number => {
    const teamId = (bet.betData as MatchOddsBetData).teamId;

    return gameStateOdds.simulatorMatchOdds?.find(
      (matchOdds) => matchOdds.teamId === teamId.value
    ).price;
  };

  const getRunsLineOdds = (
    bet: StoredBet,
    gameStateOdds: StoredGameStateOdds
  ): number => {
    const inningsRunsBetData = bet.betData as InningsRunsBetData;
    const innings = inningsRunsBetData.innings;

    return gameStateOdds.simulatorRunsLineOdds
      ?.get(innings)
      ?.find((runsLineOdds) => runsLineOdds.overs === 20).price;
  };

  const linkBetToMatch = (bet: StoredBet, match: MatchInfo) => {
    setLoading(true);
    services.betScraperService
      .linkBets(bet.source, bet.sourceEventId, match.matchId.value)
      .then(() => {
        setIsLinkModalOpen(false);
        setSelectedBetId(null);
        onBetsUpdated();
      })
      .finally(() => setLoading(false));
  };

  const onUnlinkHandler = (bet: StoredBet) => {
    setLoading(true);
    services.betScraperService
      .unlinkBets(bet.sourceEventId)
      .then(() => {
        onBetsUpdated();
      })
      .finally(() => setLoading(false));
  };

  const openLinkModal = (bet: StoredBet) => {
    setSelectedBetId(bet.sourceEventId);
    setIsLinkModalOpen(true);
  };

  const matchMenuItems = (bet: StoredBet) => {
    return (
      <FormControl fullWidth>
        <Select
          value=""
          onChange={(event) => {
            const matchId = event.target.value;
            const selectedMatch = matches.find(
              (match) => match.matchId.value === matchId
            );
            if (selectedMatch) {
              linkBetToMatch(bet, selectedMatch);
            }
          }}
          displayEmpty
        >
          <MenuItem value="" disabled>
            Select Match
          </MenuItem>
          {matches.map((match) => (
            <MenuItem key={match.matchId.value} value={match.matchId.value}>
              {match.toString()}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const rows = Object.keys(groupedBets).map((matchId, index) => {
    const matchBets = groupedBets[matchId];
    matchBets.sort((a, b) => a.sourceMarketId.localeCompare(b.sourceMarketId));
    const isMatchOpen = openMatches[matchId] || false;
    const betOddsMap: Map<number, BetOdds> = new Map();
    let currentMarket = "";
    matchBets.forEach((bet, index) => {
      const oddsForBet = bet.matchId && getOurOdds(bet);
      if (oddsForBet) {
        betOddsMap.set(index, oddsForBet);
      }
    });
    const cols = [
      "Bet Type",
      "Result",
      "Matched Odds",
      "Sim Odds",
      "Original Stake",
      "Matched Stake",
      "Time Placed",
      "Source",
      "Innings",
      "Next Innings",
      "Over",
      "Next Over",
      "Score",
      "Next Score",
      "Additional Details",
    ];

    return (
      <Fragment key={index}>
        <TableRow>
          <TableCell>
            <IconButton size="small" onClick={() => toggleMatch(matchId)}>
              {isMatchOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
          <TableCell>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {matchBets[0].matchId ? (
                <a href={`/match?matchId=${matchBets[0].matchId.value}`}>
                  {matchBets[0].matchName}
                </a>
              ) : (
                matchBets[0].matchName
              )}
              <div>
                {linksBySourceEventId[matchBets[0].sourceEventId] ? (
                  <TooltipIconButton
                    title="Unlink match"
                    onClick={() => onUnlinkHandler(matchBets[0])}
                    icon={"link_off"}
                  />
                ) : (
                  <TooltipIconButton
                    title="Link to a match"
                    onClick={() => openLinkModal(matchBets[0])}
                    icon={"add_link"}
                  />
                )}
              </div>
            </div>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={13} style={{ paddingBottom: 0, paddingTop: 0 }}>
            <Collapse in={isMatchOpen} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      {cols.map((col) => (
                        <TableCell key={col}>{col}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {matchBets.map((bet: StoredBet, betIndex: number) => {
                      const filteredBetData = bet.betData
                        ? Object.entries(bet.betData).filter(
                            ([key]) =>
                              !FIELDS_TO_HIDE.includes(key.toLowerCase())
                          )
                        : [];

                      const showMarketNameHeader =
                        bet.sourceMarketId !== currentMarket;
                      currentMarket = bet.sourceMarketId;
                      const betOdds = betOddsMap.get(betIndex);

                      return (
                        <>
                          {showMarketNameHeader && (
                            <TableRow>
                              <TableCell
                                colSpan={cols.length}
                                sx={{ background: "#eeeeee" }}
                              >
                                {bet.marketName}
                              </TableCell>
                            </TableRow>
                          )}
                          <TableRow key={betIndex}>
                            <TableCell>
                              <Tooltip title={bet.betDirection}>
                                <div
                                  className={
                                    "betfair-" + bet.betDirection.toLowerCase()
                                  }
                                  style={{
                                    display: "inline-block",
                                    width: "10px",
                                    height: "100%",
                                    lineHeight: "normal",
                                    marginRight: "8px",
                                    verticalAlign: "middle",
                                  }}
                                />
                              </Tooltip>
                              <span
                                style={{
                                  display: "inline-block",
                                  verticalAlign: "middle",
                                }}
                              >
                                {betTypeReadableValues[bet.betType] ||
                                  bet.betType}
                              </span>
                            </TableCell>
                            <TableCell>{bet.betResult}</TableCell>
                            <TableCell>{bet.odds}</TableCell>
                            <TableCell>{format(betOdds?.odds)}</TableCell>
                            <TableCell>{format(bet.originalStake)}</TableCell>
                            <TableCell>{format(bet.matchedStake)}</TableCell>
                            <TableCell>
                              {new Date(bet.placedTime).toLocaleString()}
                            </TableCell>
                            <TableCell>
                              {bet.source === BetSource.WINNER_7 ? (
                                <Tooltip title="Winner 7">
                                  <Filter7Icon />
                                </Tooltip>
                              ) : (
                                <Tooltip title="Betfair">
                                  <SwapVertIcon />
                                </Tooltip>
                              )}
                            </TableCell>
                            <TableCell>{betOdds?.innings}</TableCell>
                            <TableCell>
                              {betOdds?.nextGameStateInnings}
                            </TableCell>
                            <TableCell>{betOdds?.overs}</TableCell>
                            <TableCell>{betOdds?.nextGameStateOvers}</TableCell>
                            <TableCell>{betOdds?.score}</TableCell>
                            <TableCell>{betOdds?.nextGameStateScore}</TableCell>
                            <TableCell>
                              {filteredBetData.length > 0 && (
                                <ul style={{ padding: 0, listStyle: "none" }}>
                                  {filteredBetData.map(([key, value]) => (
                                    <li key={key}>
                                      {key}: {value?.toString() ?? "N/A"}
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  });

  return (
    <>
      <TableContainer sx={{ overflowX: "scroll" }}>
        <Table>
          <TableBody>{rows}</TableBody>
        </Table>
      </TableContainer>

      {selectedBetId && (
        <CreationDialog
          open={isLinkModalOpen}
          label={"Link to Match"}
          invalid={false}
          disabled={false}
          onCancel={() => {
            setIsLinkModalOpen(false);
            setSelectedBetId(null);
          }}
          onProceed={() => {
            setIsLinkModalOpen(false);
            setSelectedBetId(null);
          }}
          proceedText="OK"
          showCancelButton={true}
        >
          {matchMenuItems(groupedBets[selectedBetId][0])}
        </CreationDialog>
      )}
    </>
  );
}
