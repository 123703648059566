import { Icon, Switch } from "@mui/material";
import { Component } from "react";
import { Subscription } from "rxjs";

import {
  AdminPreferences,
  AdminPreferencesField,
  MatchSpecificSettings,
  ToggleType,
  humanReadableToggleTypes,
} from "../../types/preferences/admin-preferences";
import { EmployeeRoute } from "../../types/route-helpers";
import { services } from "../../types/services";
import { clonePercentDistributionBiasData } from "../../types/stats/ground-stats";
import NumberSelector from "../entity-management/entity-selectors/number-selector";
import TooltipIconButton from "../navigation-bar/tooltip-icon-button";
import {
  PercentDistributionValue,
  PercentsDistributionBiasAdjuster,
} from "../stats-editing-components/percent-distribution-bias-adjuster";

import BowlingTypeByPhaseMultipliersComponent from "./bowling-type-by-phase-multipliers-component";
import { ConfidenceSettingTable } from "./confidence-setting-table";
import { MatchSpecificSettingsComponent } from "./match-specific-settings-component";

interface State {
  adminPreferences: AdminPreferences;
  expandedRow: number;
  powerplayPercentsExpanded: boolean;
}

export const bowlingTypeByPhaseMultipliersConfig = [
  {
    property: "spinMultipliersByPhase",
    title: "Spin",
  },
  {
    property: "paceMultipliersByPhase",
    title: "Pace",
  },
];

export class AdminSettingsPage extends Component<{}, State> {
  private subscriptions: Subscription[] = [];
  private static readonly DEFAULT_STATE = {
    adminPreferences: null,
    expandedRow: null,
    powerplayPercentsExpanded: false,
  };

  constructor(props) {
    super(props);
    this.state = AdminSettingsPage.DEFAULT_STATE;
  }

  componentDidMount() {
    this.subscriptions.push(
      services.userService.adminPreferencesSubject.subscribe(
        (update: AdminPreferences) =>
          this.setState({ adminPreferences: update })
      )
    );
  }

  componentWillUnmount(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }

  private updatePreferences(field: string, value: AdminPreferencesField) {
    const toUpdate = {
      ...this.state.adminPreferences,
      [field]: value,
    };
    services.userService.updateAdminPreferences(toUpdate);
  }

  private updateTogglePreference(toggleType: ToggleType) {
    const updatedToggles = this.state.adminPreferences.toggles.map((toggle) => {
      if (toggle.toggleType === toggleType) {
        return {
          ...toggle,
          enabled: !toggle.enabled,
        };
      }

      return toggle;
    });

    this.updatePreferences("toggles", updatedToggles);
  }

  private toggleUseNewPowerplayModule() {
    const toUpdate = {
      ...this.state.adminPreferences,
      useNewPowerplayModule: !this.state.adminPreferences.useNewPowerplayModule,
    };
    services.userService.updateAdminPreferences(toUpdate);
  }

  private updateDefaultAdminConfidences(
    type: string,
    field: string,
    value: number
  ) {
    const toUpdate: AdminPreferences = {
      ...this.state.adminPreferences,
      defaultAdminConfidences: {
        ...this.state.adminPreferences.defaultAdminConfidences,
        [type]: {
          ...this.state.adminPreferences.defaultAdminConfidences[type],
          [field]: value,
        },
      },
    };
    services.userService.updateAdminPreferences(toUpdate);
  }

  private createMatchSpecificSettings() {
    const currentMatchSpecificSettings =
      this.state.adminPreferences.matchSpecificSettings;
    currentMatchSpecificSettings.unshift({
      name: "Untitled",
      createdAt: Date.now(),
      matchTypes: [],
      matchFormats: [],
      teams: [],
      series: [],
      twmAtLastBallInnings1:
        this.state.adminPreferences.defaultTwmAtLastBallInnings1,
      twmAdditionInnings1:
        this.state.adminPreferences.defaultTwmAdditionInnings1,
      twmAtLastBallInnings2:
        this.state.adminPreferences.defaultTwmAtLastBallInnings2,
      twmAdditionInnings2:
        this.state.adminPreferences.defaultTwmAdditionInnings2,
      secondInningsPushAdjustment:
        this.state.adminPreferences.defaultSecondInningsPushAdjustment,
      secondInningsPushDilution:
        this.state.adminPreferences.defaultSecondInningsPushDilution,
      adminConfidences: this.state.adminPreferences.defaultAdminConfidences,
      powerplayPushAdjust: this.state.adminPreferences.powerplayPushAdjust,
      powerplayWicketBias: this.state.adminPreferences.powerplayWicketBias,
      powerplayPercentBiases: clonePercentDistributionBiasData(
        this.state.adminPreferences.powerplayPercentBiases
      ),
      useNewPowerplayModule: this.state.adminPreferences.useNewPowerplayModule,
      surgeStrikeRateBiasInnings1:
        this.state.adminPreferences.surgeStrikeRateBiasInnings1,
      surgeWicketBiasInnings1:
        this.state.adminPreferences.surgeWicketBiasInnings1,
      surgeStrikeRateBiasInnings2:
        this.state.adminPreferences.surgeStrikeRateBiasInnings2,
      surgeWicketBiasInnings2:
        this.state.adminPreferences.surgeWicketBiasInnings2,
      spinMultipliersByPhase:
        this.state.adminPreferences.spinMultipliersByPhase,
      paceMultipliersByPhase:
        this.state.adminPreferences.paceMultipliersByPhase,
    });
    const toUpdate = {
      ...this.state.adminPreferences,
      matchSpecificSettings: currentMatchSpecificSettings,
    };
    services.userService.updateAdminPreferences(toUpdate);
  }

  private updateBias(property: PercentDistributionValue, value: number) {
    const toUpdate = {
      ...this.state.adminPreferences,
      powerplayPercentBiases: {
        ...this.state.adminPreferences.powerplayPercentBiases,
        [property]: value,
      },
    };
    services.userService.updateAdminPreferences(toUpdate);
  }

  private updateMatchSpecificSettings(
    matchSpecificSettings: MatchSpecificSettings,
    order: number
  ) {
    const currentMatchSpecificSettings =
      this.state.adminPreferences.matchSpecificSettings;
    currentMatchSpecificSettings[order] = matchSpecificSettings;
    const toUpdate = {
      ...this.state.adminPreferences,
      matchSpecificSettings: currentMatchSpecificSettings,
    };
    services.userService.updateAdminPreferences(toUpdate);
  }

  private deleteMatchSpecificSettings(order: number) {
    const currentMatchSpecificSettings =
      this.state.adminPreferences.matchSpecificSettings;
    currentMatchSpecificSettings.splice(order, 1);
    const toUpdate = {
      ...this.state.adminPreferences,
      matchSpecificSettings: currentMatchSpecificSettings,
    };
    services.userService.updateAdminPreferences(toUpdate);
  }

  public render() {
    return (
      <EmployeeRoute>
        <div className="full-push-background-light with-navbar">
          <div className="page-title-and-buttons">
            <div className="page-title">Admin Settings</div>
          </div>
          {!!this.state.adminPreferences && (
            <div className="admin-settings-content">
              <div className="admin-settings-default-content">
                <div>
                  <div className="match-specific-settings-section-header">
                    Default Settings
                  </div>
                  <hr />
                  <div className="admin-settings-default-content-section-header">
                    Thought Wicket Multiplier Defaults
                  </div>
                  <div className="thought-wicket-multiplier-settings">
                    <div className="thought-wicket-multiplier-section">
                      <div>Batting First</div>
                      <NumberSelector
                        label={"Multiplier at last ball"}
                        min={0}
                        max={10}
                        step={0.01}
                        decimalPlaces={2}
                        initial={
                          this.state.adminPreferences
                            .defaultTwmAtLastBallInnings1
                        }
                        onValid={(valid: number) =>
                          this.updatePreferences(
                            "defaultTwmAtLastBallInnings1",
                            valid
                          )
                        }
                      />
                      <NumberSelector
                        label={"Addition per ball"}
                        min={0}
                        max={1}
                        step={0.0001}
                        decimalPlaces={4}
                        initial={
                          this.state.adminPreferences.defaultTwmAdditionInnings1
                        }
                        onValid={(valid: number) =>
                          this.updatePreferences(
                            "defaultTwmAdditionInnings1",
                            valid
                          )
                        }
                      />
                    </div>
                    <div className="thought-wicket-multiplier-section">
                      <div>Batting Second</div>
                      <NumberSelector
                        label={"Multiplier at last ball"}
                        min={0}
                        max={10}
                        step={0.01}
                        decimalPlaces={2}
                        initial={
                          this.state.adminPreferences
                            .defaultTwmAtLastBallInnings2
                        }
                        onValid={(valid: number) =>
                          this.updatePreferences(
                            "defaultTwmAtLastBallInnings2",
                            valid
                          )
                        }
                      />
                      <NumberSelector
                        label={"Addition per ball"}
                        min={0}
                        max={1}
                        step={0.0001}
                        decimalPlaces={4}
                        initial={
                          this.state.adminPreferences.defaultTwmAdditionInnings2
                        }
                        onValid={(valid: number) =>
                          this.updatePreferences(
                            "defaultTwmAdditionInnings2",
                            valid
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
                <hr />
                <div>
                  <div className="admin-settings-default-content-section-header">
                    Bowling Type by Phase Multipliers
                  </div>
                  {bowlingTypeByPhaseMultipliersConfig.map((config) => (
                    <BowlingTypeByPhaseMultipliersComponent
                      value={this.state.adminPreferences[config.property]}
                      title={config.title}
                      onChange={(updatedMultipliers: number[][]) =>
                        this.updatePreferences(
                          config.property,
                          updatedMultipliers
                        )
                      }
                      key={config.property}
                    />
                  ))}
                </div>
                <hr />
                <div>
                  <div className="admin-settings-default-content-section-header">
                    Second Innings Push Adjust Defaults
                  </div>
                  <div className="second-innings-push-adjust-settings">
                    <NumberSelector
                      label={"Default Adjustment"}
                      min={0}
                      max={1}
                      step={0.01}
                      decimalPlaces={2}
                      initial={
                        this.state.adminPreferences
                          .defaultSecondInningsPushAdjustment
                      }
                      onValid={(valid: number) =>
                        this.updatePreferences(
                          "defaultSecondInningsPushAdjustment",
                          valid
                        )
                      }
                    />
                    <NumberSelector
                      label={"Default Dilution"}
                      min={0}
                      max={1}
                      step={0.01}
                      decimalPlaces={2}
                      initial={
                        this.state.adminPreferences
                          .defaultSecondInningsPushDilution
                      }
                      onValid={(valid: number) =>
                        this.updatePreferences(
                          "defaultSecondInningsPushDilution",
                          valid
                        )
                      }
                    />
                  </div>
                </div>
                <hr />
                <div>
                  <div className="admin-settings-default-content-section-header">
                    Powerplay Default Settings
                  </div>
                  <div className="second-innings-push-adjust-settings">
                    <div>
                      <div>Use New Powerplay Module</div>
                      <Switch
                        checked={
                          this.state.adminPreferences.useNewPowerplayModule
                        }
                        onChange={() => this.toggleUseNewPowerplayModule()}
                        disabled={false}
                      />
                    </div>
                    <NumberSelector
                      label={"Powerplay Default Push Adjustment"}
                      min={-100}
                      max={100}
                      step={1}
                      decimalPlaces={0}
                      initial={this.state.adminPreferences.powerplayPushAdjust}
                      onValid={(valid: number) =>
                        this.updatePreferences("powerplayPushAdjust", valid)
                      }
                    />
                    <NumberSelector
                      label={"Powerplay Wicket Bias"}
                      min={0}
                      max={10}
                      step={0.01}
                      decimalPlaces={2}
                      initial={this.state.adminPreferences.powerplayWicketBias}
                      onValid={(valid: number) =>
                        this.updatePreferences("powerplayWicketBias", valid)
                      }
                    />
                    <div
                      className="powerplay-percents-expander"
                      onClick={() =>
                        this.setState({
                          powerplayPercentsExpanded:
                            !this.state.powerplayPercentsExpanded,
                        })
                      }
                    >
                      <div>Powerplay Percent Bias</div>
                      <Icon>
                        {this.state.powerplayPercentsExpanded
                          ? "expand_less"
                          : "expand_more"}
                      </Icon>
                    </div>
                    {this.state.powerplayPercentsExpanded && (
                      <PercentsDistributionBiasAdjuster
                        biases={
                          this.state.adminPreferences.powerplayPercentBiases
                        }
                        updateValue={(
                          property: PercentDistributionValue,
                          value: number
                        ) => this.updateBias(property, value)}
                      />
                    )}
                  </div>
                </div>
                <hr />
                <div>
                  <div className="admin-settings-default-content-section-header">
                    Surge Default Settings
                  </div>
                  <div className="thought-wicket-multiplier-settings">
                    <div className="thought-wicket-multiplier-section">
                      Innings 1
                      <NumberSelector
                        label={"Strike Rate Bias"}
                        min={0}
                        max={10}
                        step={0.01}
                        decimalPlaces={4}
                        initial={
                          this.state.adminPreferences
                            .surgeStrikeRateBiasInnings1
                        }
                        onValid={(valid: number) =>
                          this.updatePreferences(
                            "surgeStrikeRateBiasInnings1",
                            valid
                          )
                        }
                      />
                      <NumberSelector
                        label={"Wicket % Bias"}
                        min={0}
                        max={10}
                        step={0.01}
                        decimalPlaces={4}
                        initial={
                          this.state.adminPreferences.surgeWicketBiasInnings1
                        }
                        onValid={(valid: number) =>
                          this.updatePreferences(
                            "surgeWicketBiasInnings1",
                            valid
                          )
                        }
                      />
                    </div>
                    <div className="thought-wicket-multiplier-section">
                      Innings 2
                      <NumberSelector
                        label={"Strike Rate Bias"}
                        min={0}
                        max={10}
                        step={0.01}
                        decimalPlaces={4}
                        initial={
                          this.state.adminPreferences
                            .surgeStrikeRateBiasInnings2
                        }
                        onValid={(valid: number) =>
                          this.updatePreferences(
                            "surgeStrikeRateBiasInnings2",
                            valid
                          )
                        }
                      />
                      <NumberSelector
                        label={"Wicket % Bias"}
                        min={0}
                        max={10}
                        step={0.01}
                        decimalPlaces={4}
                        initial={
                          this.state.adminPreferences.surgeWicketBiasInnings2
                        }
                        onValid={(valid: number) =>
                          this.updatePreferences(
                            "surgeWicketBiasInnings2",
                            valid
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="admin-settings-default-content-section-header">
                  Confidence Limits
                </div>
                <ConfidenceSettingTable
                  adminConfidences={
                    this.state.adminPreferences.defaultAdminConfidences
                  }
                  onUpdate={(type, field, value) =>
                    this.updateDefaultAdminConfidences(type, field, value)
                  }
                />
              </div>
              <hr />
              <div className="admin-settings-default-content-section-header">
                Toggles
                {this.state.adminPreferences.toggles.map((toggle) => {
                  return (
                    <div key={toggle.toggleType}>
                      <p>{humanReadableToggleTypes[toggle.toggleType]}</p>
                      <div>
                        <Switch
                          checked={toggle.enabled}
                          onChange={() =>
                            this.updateTogglePreference(toggle.toggleType)
                          }
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {!!this.state.adminPreferences && (
            <div className="admin-settings-content">
              <div className="admin-settings-specific-match-settings-content">
                <div className="admin-settings-match-specific-buttons">
                  <div>Match Specific Settings</div>
                  <TooltipIconButton
                    title={"Add Match Specific Setting"}
                    onClick={() => this.createMatchSpecificSettings()}
                    icon={"add"}
                  />
                </div>
                {this.state.adminPreferences.matchSpecificSettings.length ===
                  0 && (
                  <div className="italic">No Match Specific Preferences</div>
                )}
                {this.state.adminPreferences.matchSpecificSettings.map(
                  (matchSpecificSettings, order) => (
                    <div key={`match-specific-settings-row-${order}`}>
                      <MatchSpecificSettingsComponent
                        initialSettings={matchSpecificSettings}
                        onUpdate={(updatedMatchSpecificSettings) =>
                          this.updateMatchSpecificSettings(
                            updatedMatchSpecificSettings,
                            order
                          )
                        }
                        onDelete={() => this.deleteMatchSpecificSettings(order)}
                        onExpand={() =>
                          this.setState({
                            expandedRow:
                              order === this.state.expandedRow ? null : order,
                          })
                        }
                        expanded={this.state.expandedRow === order}
                      />
                    </div>
                  )
                )}
              </div>
            </div>
          )}
        </div>
      </EmployeeRoute>
    );
  }
}
